import './PetCardList.css';
import React from "react";
import PetCard from '../pet-card/PetCard'
import {Container, Row, Col} from 'react-bootstrap'

function PetCardList(props) {
  return (
      <Container>
        <Row className="showGrid">
          <Col lg={{span:4,offset:4}}>
            {props.petCardList.map(petCard => <PetCard key={petCard.pet.id} petCard={petCard} />)}
          </Col>
        </Row>
      </Container>
  );
}

export default PetCardList;