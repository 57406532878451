const get = (urlPath) => {
    const url = "https://dev-api.freepetz.com" + urlPath;
    return fetch(url, { method: "GET", mode: "cors", headers: { 'Content-Type': "application/json" }})
    .then(response => response.json());
};

const post = (urlPath, input) => {
    const url = "https://dev-api.freepetz.com" + urlPath;
    return fetch(url, { method: "POST", mode: "cors", body: input, headers: { 'Content-Type': "application/json" }})
    .then(response => response.json());
};

const put = (urlPath, input) => {
    const url = "https://dev-api.freepetz.com" + urlPath;
    return fetch(url, { method: "PUT", mode: "cors", body: input, headers: { 'Content-Type': "application/json" }})
    .then(response => response.json());
};

const senddelete = (urlPath) => {
    const url = "https://dev-api.freepetz.com" + urlPath;
    return fetch(url, { method: "DELETE", mode: "cors", headers: { 'Content-Type': "application/json" }})
    .then(response => response.json());
};

const ApiClient = { get, post, put, senddelete };
export default ApiClient;