import './Location.css';
import React from "react";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import {Container, Row, Col} from 'react-bootstrap'
import { geolocated } from "react-geolocated";
import LocationApi from '../../../../services/location';

const buttonStyle = { marginRight: "10px" };

class Location extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      zip: "",
      lat: 0,
      long: 0
    };
    this.raiseState = props.onSubmit;
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.searchByCurrentLocation = this.searchByCurrentLocation.bind(this);
  }

  handleSubmit(event) {
    event.preventDefault();
    console.log("Zip is :", this.state.zip);
    // Convert Zip to Lat Long
    LocationApi.lookupGeoCode(this.state.zip).then(data => {
      this.setState({lat: data.latitude});
      this.setState({long: data.longitude});
      if(this.raiseState) {
        this.raiseState(data.latitude, data.longitude)
      }
    });
  };

  handleChange(event) {
    localStorage.setItem("zip", event.target.value);
    this.setState({zip: event.target.value});
  }

  searchByCurrentLocation () {
    const { latitude, longitude } = this.props.coords;
    this.setState({lat: latitude});
    this.setState({long: longitude});
    console.log("Lat is :", latitude);
    console.log("Long is :", longitude);
    if(this.raiseState) {
        this.raiseState(latitude, longitude)
    }
  };

  render() {
    return (
      <Container style={{marginBottom: 20}}>
        <Row>
          <Col lg={{span:6,offset:3}}>
            Enter your Zip Code or Share your Current Location
            <Form noValidate onSubmit={this.handleSubmit}>
              <Form.Row>
                <Form.Group as={Col} md="12" controlId="zip">
                  <Form.Control
                    type="text"
                    name="zip"
                    placeholder="Zip"
                    value={this.state.zip}
                    onChange={this.handleChange}
                    //isInvalid={touched.zip && errors.zip}
                  />
                  <Form.Control.Feedback type="invalid">
                    {/*errors.zip*/}
                  </Form.Control.Feedback>
                </Form.Group>
              </Form.Row>
              <Button
                type="submit"
                style={buttonStyle}
              >
                Search
              </Button>
              <Button
                type="button"
                onClick={this.searchByCurrentLocation}
                style={buttonStyle}
                disabled={!this.props.coords}
              >
                  Use My Location
              </Button>
            </Form>
          </Col>
        </Row>
      </Container>
    );
  }
}

export default geolocated({
    positionOptions: {
      enableHighAccuracy: false
    },
    userDecisionTimeout: 5000
  })(Location);