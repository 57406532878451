import './PetCard.css';
import React from "react";
import Card from 'react-bootstrap/Card'

function PetCard(props) {
  return (
    <Card style={{marginBottom: 20, borderRadius: "30px"}}>
      <Card.Img variant="top" src={props.petCard.pet.imageURI} alt={props.petCard.pet.description} style={{borderRadius: "30px 30px 0px 0px"}} />
      <Card.Body>
        <Card.Title>{props.petCard.pet.name}</Card.Title>
        <Card.Text>
        {props.petCard.pet.description}
        </Card.Text>
      </Card.Body>
      <Card.Footer style={{borderRadius: "0px 0px 30px 30px"}}>
        <small className="text-muted">{Math.round(props.petCard.distance)} miles away</small>
      </Card.Footer>
    </Card>
  );
}

export default PetCard;