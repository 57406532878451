import './Main.css';
import React from "react";
import Location from './components/location/Location';
import PetCardList from './components/pet-card-list/PetCardList';
import PetApi from '../../services/pet';

class Main extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showPetCardList: false,
      lat: 0,
      long: 0,
      petCardList: []
    }

    this.onSubmit = this.onSubmit.bind(this);
  }

  onSubmit(latitude, longitude) {
    this.setState({lat: latitude});
    this.setState({long: longitude});
    console.log("Lat is :", latitude);
    console.log("Long is :", longitude);
    PetApi.findPetsByLatLong(latitude, longitude, 5000).then(data => {
      this.setState({petCardList: data});
      this.setState({showPetCardList: true});
    });
  }

  render() {
    return (
      <div className="app-main">
          Welcome to Free Petz!
          <Location onSubmit={this.onSubmit} />
          {this.state.showPetCardList
            ? <PetCardList petCardList={this.state.petCardList} />
            : <span> Please set your Location to see Free Petz!</span>
          }
          <br />
      </div>
    );
  }
}

export default Main;